(function () {
  app.controller("MakePaymentCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.credit_card = {};
      $scope.save_personal_info = true;
      $scope.error_message = "";
      $scope.payment_amount = 0;

      $scope.init = () => {};

      $scope.chooseSplitPayment = function () {
        $scope.payment_amount = 0;
        $.each($scope.payment_installments, function (i, p) {
          if (p.checked) {
            $scope.payment_amount += parseFloat(p.amount);
          }
        });
      };

      $scope.submit = function () {
        $scope.error_message = "";
        if ($scope.credit_card.is_new_one) {
          $scope.credit_card = $scope.validateCreditCard($scope.credit_card);

          if ($scope.credit_card.error_message) {
            $scope.error_message = $scope.credit_card.error_message;
            bootbox.alert($scope.error_message);
            return false;
          }

          $(".loading").fadeIn();
          $scope.credit_card = $scope.submitCreditCard($scope.credit_card);
          $timeout(function () {
            if ($.trim($scope.credit_card.token) == "") {
              $(".loading").fadeOut();
              $scope.error_message = "Your Card is invalid!";
              bootbox.alert($scope.error_message);
              return false;
            }
            doPayment();
          }, 2000);
        } else {
          doPayment();
        }
      };

      function doPayment() {
        var split_payment_ids = [];

        $.each($scope.payment_installments, function (i, p) {
          if (p.checked) {
            split_payment_ids.push(p.id);
          }
        });

        var opt = {
          split_payment_ids: split_payment_ids,
          stripe_token: $scope.credit_card.token,
          stripe_customer_id: $scope.credit_card.charge_customer_id,
          card_name: $scope.credit_card.is_new_one
            ? $scope.new_card_name
            : $scope.credit_card.name,
        };

        $.post($scope.submit_url, opt, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              location.href = "/payments";
            } else {
              bootbox.alert({
                message: rs.message || "error",
                callback: function () {
                  location.href = "/payments";
                },
              });
            }
          });
        });
      }
    },
  ]);
}.call(this));
