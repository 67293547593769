(function () {
  app.controller("YourRoomCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.init = function () {};

      $scope.showConfirmationStepPopup = function () {
        let params = {
          invitation_action: $scope.invitation_action,
          room_member_id: $scope.room_member_id,
        };
        $.get(`/rooms/confirmation_step`, params, function (rs) {});
      };
    },
  ]);
}.call(this));
