(function () {
  app.controller("ChangeRoomOccupancyLevelCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.error_massage = "";
      $scope.input_occupant = null;
      $scope.trip_date_selection = null;

      $scope.init = function () {};

      $scope.changeSelection = function (e) {
        $scope.trip_date_selection = e.trip_date_selection;
      }

      $scope.validateInputOccupantStep = function () {
        $scope.error_massage = "";
        if ($.trim($scope.input_occupant) == "") {
          $scope.error_massage = "Please enter #occupants";
          return false;
        } else if ($scope.input_occupant <= 0) {
          $scope.error_massage = "Min number occupants is 1!";
          return false;
        } else if ($scope.input_occupant >= $scope.current_occupants) {
          $scope.error_massage =
            "Your new occupancy level cannot be equal or higher than the current occupancy level of " + String($scope.current_occupants) + "!";
          return false;
        } else if ($scope.input_occupant < $scope.valid_room_members) {
          $scope.error_massage =
            "Your new occupancy level cannot be lower than the pending or accepted guests, To change the occupancy to this level, Remove some guests from your invited list";
          return false;
        }else if (!$scope.trip_date_selection && $scope.trip_date_selection_on) {
          $scope.error_massage =
            "Please choose a trip date";
          return false;
        }
        return true;
      };

      $scope.validateChangeButton = function () {
        return (
          $scope.validateInputOccupantStep()
        );
      };

      $scope.changeOccupancy = function () {
        if (
          $scope.validateChangeButton()
        ) {
          executeChangeRoomOccupancyLevel();
        }
      };

      function executeChangeRoomOccupancyLevel() {
        var params = {
          occupants: $scope.input_occupant,
          trip_date_selection_id: $scope.trip_date_selection_on ? $scope.trip_date_selection : null,
          room_id: $scope.room_id
        };

        $.post($scope.exec_change_room_occupancy_level, params, function (rs) {
          $timeout(function () {
            if (!rs.succeed) {
              if (rs.message) {
                $scope.error_massage = rs.message;
              }
            }
          });
        });
      }
    },
  ]);
}.call(this));
