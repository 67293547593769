(function () {
  app.controller("RoomPriceCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.room_price_data = {};

      $scope.init = function () {};

      $scope.changeMaxOccupants = function (room) {
        $scope.room_price_data[room.id] = {}
        if (room.max_occupants_per_room > 0) {
          for (var i = 1; i <= room.max_occupants_per_room; i++) {
            $scope.room_price_data[room.id][i] = 0;
          }
        }
        room.room_price_data = $scope.room_price_data[room.id]
      };

      $scope.addNewRoom = function (id) {
        $scope.rooms.push({
            "id": $scope.counter,
            "trip_date_id": $scope.trip_date.id,
            "inventory_id": null,
            "room_price_data": "{}",
            "max_occupants_per_room": null
        })
        // filter out the options that are already selected
        // $scope.hotel_room_array.filter((hotel_room) => {
        //   return hotel_room_array[0] != roomCombination
        // });
        setTimeout(()=> {
          let select2 = $('.select2').last()
          select2.select2();
          $('#room_tab_' + $scope.counter).tab('show');
          $scope.counter += 1;
        }
       ,100);
      };

      $scope.removeRoom = function (index) {
        $scope.rooms = $scope.rooms.filter((room, idx) => {
          return idx != index
        });
      };

      $scope.getUniqueRoomTypeOptions = function (inventory_id) {
        let uniqueOptions = [];
        let uniqueOptionIds = [];
        let previously_selected_room =  $scope.roomCombination.find(room => room.id == inventory_id)
        if (previously_selected_room) {
          uniqueOptionIds.push(previously_selected_room.id);
          uniqueOptions.push(previously_selected_room);
        }

        $scope.availableRoomCombination.forEach(function(option) {
          if (uniqueOptionIds.indexOf(option.id) === -1) {
            uniqueOptionIds.push(option.id);
            uniqueOptions.push(option);
          }
        });
        return uniqueOptions;
    }

    },
  ]);
}.call(this));
