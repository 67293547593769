(function () {
  app.controller("SendSMSEmailCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.message = "";
      $scope.fitler_params = {};

      $scope.init = function () {};

      $scope.execute_send_email_sms = function () {
        $scope.message = "";
        $scope.errors = {};
        $.post($scope.send_sms_email_url, $scope.fitler_params, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              location.reload();
            } else if (rs.message) {
              $scope.message = rs.message;
            }
          });
        });
      };
    },
  ]);
}.call(this));
