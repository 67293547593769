(function () {
  app.controller("ReportCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.event_ids = [];
      $scope.report_type = "";
      $scope.from_date = "";
      $scope.to_date = "";
      $scope.package_name = "";
      $scope.hotel_name = "";
      $scope.packages = [];
      $scope.hotels = [];
      $scope.page = 1;
      $scope.page_size = "10";
      $scope.data = {};
      $scope.data_orphans = {};
      $scope.status_all = true;
      $scope.status_pending = false;
      $scope.status_full = false;
      $scope.status_finalized = false;
      $scope.status_cancelled = false;
      $scope.room_statuses = [-1];

      $scope.init = () => {};

      $scope.loadMore = function (type) {
        $scope.page += 1;
        if (type == "orphans") {
          $scope.executePreview(true);
          return;
        }
        $scope.executePreview();
      };

      $scope.changeReportType = function () {
        $scope.page = 1;
        $scope.can_download = false;
        if ($scope.report_type == "room" || $scope.report_type == "inventory") {
          $scope.selectEvents();
        }
      };

      $scope.canPreview = function () {
        return (
          $scope.report_type &&
          ($scope.report_type != "accounting" ||
            ($scope.from_date && $scope.to_date)) &&
          ($scope.report_type != "room" ||
            ($scope.package_name && $scope.room_statuses.length > 0)) &&
          ($scope.report_type != "inventory" || $scope.hotel_name)
        );
      };

      $scope.selectEvents = function () {
        angular.forEach($scope.selected_events, function (value, index) {
          $scope.event_ids.push(value.id);
        });

        if ($scope.report_type == "room") $scope.get_packages();
        if ($scope.report_type == "inventory") $scope.get_hotels();
      };

      $scope.get_packages = function () {
        $scope.package_name = "";
        var opt = {
          event_ids: $scope.event_ids,
        };

        $.post($scope.get_packages_url, opt, function (rs) {
          $timeout(function () {
            if (rs && rs.packages) {
              $scope.packages = rs.packages;
            } else {
              $scope.packages = [];
            }
          });
        });
      };

      $scope.get_hotels = function () {
        $scope.hotel_name = "";
        var opt = {
          event_ids: $scope.event_ids,
        };

        $.post($scope.get_hotels_url, opt, function (rs) {
          $timeout(function () {
            if (rs && rs.hotels) {
              $scope.hotels = rs.hotels;
            } else {
              $scope.hotels = [];
            }
          });
        });
      };

      $scope.preview = function () {
        $scope.page = 1;
        if ($scope.report_type == "room") {
          $scope.executePreview(true);
        } else {
          $scope.executePreview();
        }
      };

      $scope.change_status = function (type, value) {
        if ($scope[type]) {
          $scope.room_statuses.push(value);
          $scope.room_statuses = [...new Set($scope.room_statuses)];
        } else {
          $scope.room_statuses = $scope.room_statuses.filter((s) => s != value);
        }
      };

      $scope.executePreview = function (is_orphans) {
        var opt = {
          event_ids: $scope.event_ids,
          report_type: $scope.report_type,
          from_date: $scope.from_date,
          to_date: $scope.to_date,
          package_name: $scope.package_name,
          page: $scope.page,
          page_size: $scope.page_size,
          is_orphans: is_orphans,
          room_statuses: $scope.room_statuses,
          hotel_name: $scope.hotel_name,
        };
        $.post($scope.get_preview_data_url, opt, function (rs) {
          $timeout(function () {
            $scope.can_download = true;
            if (rs && rs.succeed) {
              if ($scope.page == 1) {
                if (is_orphans) {
                  $scope.data_orphans = rs.data;
                } else {
                  $scope.data = rs.data;
                }
              } else {
                var rows;
                if (is_orphans) {
                  rows = $scope.data_orphans.rows;
                  $scope.data_orphans.rows = rows.concat(rs.data.rows);
                } else {
                  rows = $scope.data.rows;
                  $scope.data.rows = rows.concat(rs.data.rows);
                }
              }
            } else {
              $scope.data = [];
            }
          });
        });
      };

      $scope.show_load_more = function () {
        return (
          $scope.data.total > 0 &&
          ($scope.data.total > $scope.data.rows.length ||
            ($scope.report_type == "room" &&
              $scope.page_size * $scope.page < $scope.data.total))
        );
      };

      $scope.show_load_more_orphans = function () {
        return (
          $scope.data_orphans.total > 0 &&
          $scope.data_orphans.total > $scope.data_orphans.rows.length
        );
      };
    },
  ]);
}.call(this));
