(function () {
  app.controller("BookingCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.promo_code = {};
      $scope.credit_card = {};
      $scope.billing_address = {};
      $scope.steps = { products: 0, checkout: 1 };
      $scope.current_step = 0;
      $scope.event = {};
      $scope.products = [];
      $scope.payment_plans = [];
      $scope.product_data = {};
      $scope.payment_data = {
        grand_amount: 0,
        tax_amount: 0,
        processing_fee: 0,
        include_insurance: true,
        insurance_amount: 0,
        promo_code_amount: 0,
        payment_amount: 0,
        payment_option: null,
      };
      $scope.is_show_product_details = false;
      $scope.save_personal_info = true;
      $scope.error_message = "";
      $scope.init = () => {};

      $scope.setPaymentOption = function (opt) {
        $scope.payment_data.payment_option = opt;
      };

      $scope.goBackToProductsStep = function () {
        $scope.current_step = $scope.steps.products;
      };

      $scope.showProductDetails = function () {
        $scope.is_show_product_details = !$scope.is_show_product_details;
      };

      $scope.increaseProduct = function (product) {
        var temp = $scope.product_data[product.id];
        var bundle_id = product.bundle;

        if (!temp) {
          temp = {
            name: product.name,
            quantity: 0,
            unit_price: 0,
            blocks: [],
            bundle: bundle_id,
          };
        }

        if (product.stock <= temp.quantity) {
          return;
        }

        temp.quantity += 1;
        var quantity_for_block = temp.quantity;
        new_blocks = [];
        for (var i = 0; i < product.blocks.length; i++) {
          block = angular.copy(product.blocks[i]);

          if (quantity_for_block > block.quantity) {
            new_blocks.push(block);
            quantity_for_block -= block.quantity;
          } else if (
            quantity_for_block <= block.quantity &&
            quantity_for_block > 0
          ) {
            block_data = {
              product_block_id: block.product_block_id,
              quantity: quantity_for_block,
              unit_price: block.unit_price,
            };
            new_blocks.push(block_data);
            quantity_for_block = 0;
          } else {
            quantity_for_block = 0;
          }

          if (quantity_for_block == 0) {
            break;
          }
        }
        temp.blocks = new_blocks;

        var price = 0;
        $.each(temp.blocks, function (i, x) {
          price += x.unit_price * x.quantity;
        });

        temp.unit_price = price / temp.quantity;
        $scope.product_data[product.id] = temp;

        price = 0;
        $.each($scope.product_data, function (i, product) {
          price += product.unit_price * product.quantity;
        });
        $scope.payment_data.grand_amount = price;
      };

      $scope.decreaseProduct = function (product) {
        $scope.is_new = false;
        var temp = $scope.product_data[product.id];

        if (!temp || temp.quantity == 0) {
          return false;
        }

        temp.quantity -= 1;

        var price = 0;

        let block_max_price = temp.blocks.reduce((prev, curr) =>
          parseFloat(prev.unit_price) > parseFloat(curr.unit_price)
            ? prev
            : curr
        );

        $.each(temp.blocks, function (i, block) {
          if (block.product_block_id == block_max_price.product_block_id) {
            block.quantity -= 1;
            $scope.payment_data.grand_amount -= block.unit_price;
            if (block.quantity == 0) {
              temp.blocks.splice(-1, 1);
            }
          }
          price += block.unit_price * block.quantity;
        });

        temp.unit_price = price / temp.quantity;

        if (temp.quantity == 0) {
          $scope.product_data[product.id] = null;
          delete $scope.product_data[product.id];
        }
      };

      // $scope.$watch('payment_data.grand_amount', function (val) {
      //     if (!$scope.is_new) {
      //         setPaymentAmount();
      //
      //         console.log('--------------------------');
      //
      //         if ($scope.current_step == $scope.steps.checkout) {
      //             var opt = {
      //                 event_id: $scope.event.id,
      //                 payment_amount: $scope.payment_data.payment_amount
      //             }
      //
      //             $.post('/booking/get_installments', opt, function (rs) {
      //                 $timeout(function () {
      //                     $scope.payment_plans = rs;
      //                 })
      //             });
      //         }
      //     }
      // });

      $scope.goToCheckoutStep = function () {
        var opt = {
          event_id: event.id,
          product_data: $scope.product_data,
        };

        $.post("/booking/add_to_order", opt, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              $scope.product_data = rs.product_data;
              $scope.payment_data = rs.payment_data;
              $scope.payment_plans = rs.payment_plans;
              if ($scope.payment_plans.length < 2) {
                $scope.payment_data.payment_option = 0;
              }
              $scope.current_step = $scope.steps.checkout;
            } else {
              bootbox.alert(rs.message);
            }
          });
        });
      };

      $scope.applyPromoCode = function () {
        $scope.promo_code.error_message = null;
        var opt = {
          code: $scope.promo_code.code,
          payment_data: $scope.payment_data,
        };
        $.post("/booking/verify_promo_code", opt, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              $scope.promo_code = rs.promo_code;
              $scope.payment_data = rs.payment_data;
              $scope.payment_plans = rs.payment_plans;
            } else {
              $scope.promo_code.error_message = rs.message;
            }
          });
        });
      };

      $scope.removePromoCode = function () {
        $.post(
          "/booking/remove_promo_code",
          { payment_data: $scope.payment_data },
          function (rs) {
            $timeout(function () {
              if (rs.succeed) {
                $scope.promo_code = {};
                $scope.payment_data = rs.payment_data;
                $scope.payment_plans = rs.payment_plans;
              }
            });
          }
        );
      };

      $scope.doPayment = function () {
        $scope.error_message = "";

        if (
          $scope.credit_card.is_new_one &&
          $scope.payment_data.payment_amount > 0
        ) {
          $scope.credit_card.name = $scope.new_card_name;
          $scope.credit_card = $scope.validateCreditCard($scope.credit_card);

          if ($scope.credit_card.error_message) {
            $scope.error_message = $scope.credit_card.error_message;
            bootbox.alert($scope.error_message);
            return false;
          }

          $(".loading").fadeIn();
          $scope.credit_card = $scope.submitCreditCard($scope.credit_card);

          $timeout(function () {
            if ($.trim($scope.credit_card.token) == "") {
              $(".loading").fadeOut();
              $scope.error_message = "Your Card is invalid!";
              bootbox.alert($scope.error_message);
              return false;
            }

            checkoutOrder();
          }, 2000);
        } else {
          checkoutOrder();
        }
      };

      $scope.selectInsurance = function () {
        setPaymentAmount();
        getInstallments();
      };

      function setPaymentAmount() {
        var payment_amount = $scope.payment_data.grand_amount;
        payment_amount += $scope.payment_data.promo_code_amount;
        payment_amount = payment_amount <= 0 ? 0 : payment_amount;
        payment_amount += $scope.payment_data.tax_amount;
        payment_amount += $scope.payment_data.processing_fee;
        if ($scope.payment_data.include_insurance) {
          payment_amount += $scope.payment_data.insurance_amount;
        }
        $scope.payment_data.payment_amount = payment_amount;
      }

      function getInstallments() {
        var opt = {
          event_id: $scope.event.id,
          payment_amount: $scope.payment_data.payment_amount,
        };

        $.post("/booking/get_installments", opt, function (rs) {
          $timeout(function () {
            $scope.payment_plans = rs;
          });
        });
      }

      function checkoutOrder() {
        var opt = {
          event_id: $scope.event.id,
          stripe_token: $scope.credit_card.token,
          stripe_customer_id: $scope.credit_card.charge_customer_id,
          card_name: $scope.credit_card.is_new_one
            ? $scope.new_card_name
            : $scope.credit_card.name,
          promo_code_id: $scope.promo_code ? $scope.promo_code.id : null,
          payment_data: $scope.payment_data,
          billing_address: $scope.billing_address,
        };

        $.post("/booking/checkout", opt, function (rs) {
          $timeout(function () {
            if (rs.succeed) {
              location.href = "/overview";
            } else {
              bootbox.alert({
                message: rs.message,
                callback: function () {
                  location.reload();
                },
              });
            }
          });
        });
      }
    },
  ]);
}.call(this));
