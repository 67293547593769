(function () {
  app.controller("SendUserRedeemCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.user_status = [
        { id: null, name: "All" },
        { id: 0, name: "Unsent" },
        { id: 1, name: "Sent" },
      ];
      $scope.send_type = 0;
      $scope.selected_status = null;

      $scope.init = function () {};

      $scope.addSendReward = function () {
        $scope.send = 1;
      };

      $scope.cancelSendReward = function () {
        $scope.send = 0;
      };

      $scope.selectStatus = function () {
        if ($scope.selected_status == null) {
          return;
        }
        var opt = {
          status: $scope.selected_status,
          send_type: $scope.send_type,
          subject: $scope.subject,
          content: $scope.content,
          send: $scope.send,
        };

        $.get($scope.sent_user_redeem_url, opt, function (rs) {});
      };
    },
  ]);
}.call(this));
