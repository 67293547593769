(function () {
  app.controller("ControlDashboardCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.data = {};
      $scope.products = [];
      $scope.selected_event_id = null;
      $scope.selected_product_ids = [];

      $scope.init = () => {};

      $scope.getProducts = function () {
        $scope.products = [];
        $scope.data = {};
        var event_id = $.trim($scope.selected_event_id);
        if (event_id == "") {
          return;
        }

        var opt = {
          id: event_id,
        };

        $.post($scope.get_products_path, opt, function (rs) {
          if (rs) {
            $timeout(function () {
              $scope.products = rs;
              $('[ng-model="selected_product_ids"]').val(null).select2();
            });
          }
        });
      };

      $scope.viewDashboard = function () {
        $scope.data = [];
        var event_id = $.trim($scope.selected_event_id);
        var product_ids = $scope.selected_product_ids;

        if (event_id == "" || !product_ids || angular.equals(product_ids, [])) {
          return;
        }

        var opt = {
          product_ids: product_ids,
          event_id: event_id,
        };

        $.post("/control_dashboard/view_dashboard", opt, function (rs) {
          $timeout(function () {
            if (rs) {
              $scope.data = rs;
            }
          });
        });
      };

      $scope.loadMoreOrder = function (product_id) {
        let offset = $scope.data[product_id].orders.length;
        var opt = {
          product_id: product_id,
          event_id: $scope.selected_event_id,
          offset: offset,
        };
        $scope.data_oders = [];
        $.post("/control_dashboard/load_more_order", opt, function (rs) {
          $timeout(function () {
            if (rs) {
              $.merge($scope.data[product_id].orders, rs);
            }
          });
        });
      };
    },
  ]);
}.call(this));
