(function () {
  app.controller("OverviewCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      $scope.init = function () {
        //initializing angular controller
      };

      $scope.showOrderDetailsPopup = function (order_number, user_id) {
        let params = {
          order_number: order_number,
          user_id: user_id,
        };
        $.get(`/booking/get_order_details`, params, function (rs) {});
      };

      $scope.onReady = function() {
        if ($scope.require_credit_card) {
          $.get($scope.change_credit_card_user_path, function (rs) {});
        }
      };
    },
  ]);
}.call(this));
